//TODO: GROW-2166 refactor and core component move when productizing
import { useHeaderContext, useLockBodyScroll } from '@jouzen/ecom-components';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import {
  type MouseEventHandler,
  type RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import type { HeaderV4Props } from '..';

interface UseHeaderV4Props {
  isParentInverse: HeaderV4Props['inverse'];
  onHamburgerClick: HeaderV4Props['onHamburgerClick'];
}

export interface UseHeaderV4Return {
  blurElement: RefObject<HTMLButtonElement | null>;
  hamburgerElement: RefObject<HTMLButtonElement | null>;
  inverse: boolean;
  open: boolean;
  position: number;
  tabIndex: number;
  visible: boolean;
  onHamburgerButtonClick: MouseEventHandler<HTMLButtonElement>;
  onOutsideClick: () => void;
}

export function useHeaderV4({
  isParentInverse,
  onHamburgerClick,
}: UseHeaderV4Props): UseHeaderV4Return {
  const [position, setPosition] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const hamburgerElement = useRef<HTMLButtonElement>(null);
  const blurElement = useRef<HTMLButtonElement>(null);
  const context = useHeaderContext();
  const tabIndex = open ? 0 : -1;

  useLockBodyScroll(open);

  const { scrollY } = useScroll();

  useEffect(() => {
    setPosition(window.scrollY);
  }, []);

  // scroller to handle the visibility of the header
  const updateScroll = (moving: number): void => {
    setVisible(position > moving);
    setPosition(moving);
  };
  useMotionValueEvent(scrollY, 'change', (latest) => {
    updateScroll(latest);
  });

  // if reached end of menu return focus to hamburger
  useEffect(() => {
    const handleBlur = (): void => {
      hamburgerElement.current?.focus();
    };
    const hamburgerEl = hamburgerElement.current;
    const blurEl = blurElement.current;
    if (hamburgerEl && blurEl) {
      blurEl.addEventListener('blur', handleBlur);

      return () => {
        blurEl.removeEventListener('blur', handleBlur);
      };
    } else {
      return;
    }
  }, []);

  let inverse;
  if (open) {
    inverse = false;
  } else if (isParentInverse) {
    inverse = true;
  } else if (context?.inverse) {
    inverse = !!context.inverse;
  } else {
    inverse = false;
  }

  const handleHamburgerClick = (): void => {
    setOpen((prevOpen) => !prevOpen);
    onHamburgerClick?.({ open: !open });
  };

  const handleOutsideClick = (): void => {
    setOpen(false);
  };

  return {
    blurElement,
    hamburgerElement,
    inverse,
    position,
    open,
    tabIndex,
    visible,
    onHamburgerButtonClick: handleHamburgerClick,
    onOutsideClick: handleOutsideClick,
  };
}
