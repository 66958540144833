import { useDynamicConfig } from '@statsig/react-bindings';

export interface StsgLink {
  href?: string;
  id: string;
  label?: string | JSX.Element;
  title?: string;
  link?: {
    href: string;
    id: string;
    label: string | JSX.Element;
    overlay?: {
      title: string | JSX.Element;
      description?: string | JSX.Element;
      color?: string;
    };
  };
}

interface LinkModifierReturn {
  isConfigActive: boolean;
  statsigLink: StsgLink | undefined;
}

const useLinkModifierConfig = (
  configName: string,
  isGen3POP: boolean,
): LinkModifierReturn => {
  const menuLinkConfig = useDynamicConfig(configName);
  const popLinkConfigValue = menuLinkConfig.value;
  const or4POPLink = popLinkConfigValue['oura-ring-4'] as StsgLink;
  const gen3POPLink = popLinkConfigValue['oura-gen3'] as StsgLink;
  const isConfigActive = Object.keys(popLinkConfigValue).length > 0;

  const statsigLink = isGen3POP ? gen3POPLink : or4POPLink;
  return { isConfigActive, statsigLink };
};

export default useLinkModifierConfig;
