//TODO: GROW-2166 - After A/B test is done this file can be deleted, this a copy and pasted version coming from core components
import type { LinksData } from '@jouzen/ecom-components';
import { LinksCategoryId } from '@jouzen/ecom-components';

export interface DefaultHeaderData {
  headerLinks: LinksData[];
}

const headerData: DefaultHeaderData = {
  headerLinks: [
    {
      id: LinksCategoryId.WhyOuraCategory,
      link: {
        id: 'nav_ouraexperience',
        href: '/why-oura',
        label: 'why_oura_link',
      },
    },
    {
      id: LinksCategoryId.HowItWorksCategory,
      link: {
        id: 'nav_howitworks',
        href: '/how-it-works',
        label: 'how_it_works_link',
      },
    },
    {
      id: LinksCategoryId.BusinessCategory,
      link: {
        id: 'nav_business',
        href: '/business',
        label: 'for_business_link',
      },
    },
    {
      id: LinksCategoryId.ShopCategory,
      link: {
        id: 'oura-ring',
        href: '/product/rings',
        label: 'shop',
      },
    },
  ],
};

export default headerData;
