import type { SVGProps } from 'react';

interface SVGRProps {
  readonly title?: string;
  readonly titleId?: string;
}

const SvgLogoAffirm = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    viewBox="0 0 96 39"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <defs>
      <path
        id="logo-affirm_svg__a"
        d="M4.5 33.5c0-1.8 2-2.4 5.6-2.8 0 2.4-1.6 4.3-3.9 4.3-1.1 0-1.7-.6-1.7-1.5M.6 24.1l1.5 3.1C3.4 26 5.5 25 7.4 25c1.8 0 2.8.6 2.8 1.8 0 .8-.7 1.2-1.9 1.4-4.6.6-8.3 1.9-8.3 5.5 0 2.9 2 4.6 5.2 4.6 2.3 0 4.3-1.3 5.2-2.9v2.5h4.2V27.6c0-4.2-3-6-6.8-6-2.5 0-5.5 1.2-7.2 2.5"
      />
    </defs>
    <clipPath id="logo-affirm_svg__b">
      <use
        xlinkHref="#logo-affirm_svg__a"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <path
      d="M-7.3 14.3h29.2v31.3H-7.3z"
      style={{
        clipPath: 'url(#logo-affirm_svg__b)',
        fill: '#090b0c',
      }}
    />
    <defs>
      <path
        id="logo-affirm_svg__c"
        d="M54.5 24.7V22h-4.2v15.8h4.5v-7.6c0-3.6 2.2-4.7 3.7-4.7.6 0 1.4.2 1.9.6l.8-4.2c-.7-.3-1.4-.4-2-.4-2.3.1-3.8 1.1-4.7 3.2"
      />
    </defs>
    <clipPath id="logo-affirm_svg__d">
      <use
        xlinkHref="#logo-affirm_svg__c"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <path
      d="M43 14.3h25.5v30.8H43z"
      style={{
        clipPath: 'url(#logo-affirm_svg__d)',
        fill: '#090b0c',
      }}
    />
    <defs>
      <path
        id="logo-affirm_svg__e"
        d="M77.1 24.4c-.9-1.8-2.7-2.8-4.9-2.8-2.4 0-4 1.3-4.8 2.9V22H63v15.8h4.5v-8.1c0-2.9 1.5-4.3 3-4.3 1.3 0 2.5.8 2.5 3v9.5h4.5v-8.1c0-3 1.5-4.3 3-4.3 1.2 0 2.5.9 2.5 3V38h4.5V26.9c0-3.6-2.4-5.3-5.3-5.3-2.4 0-4.2 1.4-5.1 2.8"
      />
    </defs>
    <clipPath id="logo-affirm_svg__f">
      <use
        xlinkHref="#logo-affirm_svg__e"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <path
      d="M55.8 14.3h39v30.8h-39z"
      style={{
        clipPath: 'url(#logo-affirm_svg__f)',
        fill: '#090b0c',
      }}
    />
    <defs>
      <path
        id="logo-affirm_svg__g"
        d="M30.7 20.9V22h-6.9v-1.6c0-2.1 1.2-2.7 2.2-2.7.6 0 1.4.1 2 .5l1.4-3.2c-.8-.5-2.2-.9-4-.9-2.9 0-6.2 1.6-6.2 6.7V22h-2.6v3.5h2.6v12.3h4.5V25.5h6.9v12.3h4.5V25.5h4.1V22h-4.1v-1.6c0-2.1 1.2-2.7 2.2-2.7 1.1 0 2 .5 2 .5l1.4-3.2s-1.4-.9-4-.9c-2.8 0-6 1.7-6 6.8"
      />
    </defs>
    <clipPath id="logo-affirm_svg__h">
      <use
        xlinkHref="#logo-affirm_svg__g"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <path
      d="M9.4 6.9h38.7v38.2H9.4z"
      style={{
        clipPath: 'url(#logo-affirm_svg__h)',
        fill: '#090b0c',
      }}
    />
    <defs>
      <path id="logo-affirm_svg__i" d="M0 0h96v38.3H0z" />
    </defs>
    <clipPath id="logo-affirm_svg__j">
      <use
        xlinkHref="#logo-affirm_svg__i"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath: 'url(#logo-affirm_svg__j)',
      }}
    >
      <defs>
        <path id="logo-affirm_svg__k" d="M41.9-.5h55.3v39.3H41.9z" />
      </defs>
      <clipPath id="logo-affirm_svg__l">
        <use
          xlinkHref="#logo-affirm_svg__k"
          style={{
            overflow: 'visible',
          }}
        />
      </clipPath>
      <path
        d="M42 22h4.5v15.8H42z"
        style={{
          clipPath: 'url(#logo-affirm_svg__l)',
          fill: '#090b0c',
        }}
      />
      <g
        style={{
          clipPath: 'url(#logo-affirm_svg__l)',
        }}
      >
        <defs>
          <path
            id="logo-affirm_svg__m"
            d="M42.1 19.4h4.4c2.6-8.1 11.3-15.2 21.7-15.2 12.7 0 23.6 9.6 23.6 24.6 0 3.4-.4 6.4-1.3 9.1h4.3v-.1c.7-2.8 1.1-5.8 1.1-8.9C96 12 83.8 0 68.3 0 56.1 0 45.2 8.5 42.1 19.4"
          />
        </defs>
        <clipPath id="logo-affirm_svg__n">
          <use
            xlinkHref="#logo-affirm_svg__m"
            style={{
              overflow: 'visible',
            }}
          />
        </clipPath>
        <path
          d="M34.9-7.3h68.4v52.4H34.9z"
          style={{
            clipPath: 'url(#logo-affirm_svg__n)',
            fill: '#329dd8',
          }}
        />
      </g>
    </g>
  </svg>
);

export default SvgLogoAffirm;
