type headerInversePageLevelProps = Record<string, boolean>;

export const headerInversePageLevel: headerInversePageLevelProps = {
  home: true,
  '404': true,
  business: true,
  contact: true,
  developer: true,
  extend: true,
  fitness: true,
  gift: true,
  newsroom: true,
  integrations: true,
  partnerships: true,
  'pdp-rings': true,
  'science-and-research': true,
  sizing: true,
  'sleep-and-rest': true,
  'why-oura': true,
};
