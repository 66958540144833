import type { HeaderV3Props } from '@jouzen/ecom-components';
import type { DynamicConfig } from '@statsig/react-bindings';
import { useDynamicConfig } from '@statsig/react-bindings';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslations } from 'next-intl';

interface HeaderLinkProps extends Omit<DynamicConfig, 'value'> {
  value: {
    headerLinks: HeaderV3Props['headerLinks'];
  };
}

const useHeaderConfig = (): HeaderV3Props['headerLinks'] => {
  const t = useTranslations();
  const config = useDynamicConfig('global_header_links');
  const headerConfigValue = config.value as HeaderLinkProps['value'];
  const activeHeaderConfig = Object.keys(headerConfigValue).length > 0;

  if (!activeHeaderConfig) {
    return undefined;
  }

  const statsigLinks = cloneDeep(headerConfigValue.headerLinks);

  if (statsigLinks) {
    for (const links of statsigLinks) {
      if (links.link) {
        links.link.label = t(links.link.label);
      }
    }
  }

  return statsigLinks;
};

export default useHeaderConfig;
