import {
  BodyLinkV3,
  type FootnotesProps,
  TypographyV3,
} from '@jouzen/ecom-components';
import { useLocale, useTranslations } from 'next-intl';
import type { ReactNode } from 'react';

import getLocalePartialUrl from '@/utils/getLocalePartialUrl';

import {
  BUSINESS_PATHNAME,
  FITNESS_PATHNAME,
  GEN3_POP_PATHNAME,
  GIFT_PATHNAME,
  MEMBERSHIP,
  POP_PATHNAME,
  STELO_PDP_PATHNAME,
  WHY_OURA_PATHNAME,
} from '../consts/pathnames';

const useFootnotesProps = (pathname: string): FootnotesProps => {
  const t = useTranslations();
  const locale = useLocale();
  const localePartialUrl = getLocalePartialUrl(locale);

  if (pathname === POP_PATHNAME) {
    return {
      id: 'legal-footnotes',
      className: '!bg-gray-450 !pb-16',
      footnotes: [
        {
          content: t.rich('pop_gen4_battery_life_footnote', {
            ringBatteryTipsLink: (chunks: ReactNode) => (
              <BodyLinkV3
                className="text-current"
                href="https://support.ouraring.com/hc/en-us/articles/360046218953-Ring-Battery-Tips"
                target="_blank"
              >
                {chunks}
              </BodyLinkV3>
            ),
          }) as string,
          marker: '*',
        },
        {
          content: t('footnotes_membership_pricing'),
          marker: '**',
        },
      ],
    };
  }

  if (pathname === GEN3_POP_PATHNAME) {
    return {
      id: 'legal-footnotes',
      footnotes: [
        {
          content: t('pop_footnote_how_accurate_is_oura'),
          marker: '*',
          linkProps: {
            href: t('pop_footnote_how_accurate_is_oura'),
          },
        },
        {
          content: t('pop_footnote_temperature_validated_accurate'),
          marker: '**',
          linkProps: {
            href: t('pop_footnote_temperature_validated_accurate'),
          },
        },
        {
          marker: '***',
          content: t('footnotes_membership_pricing'),
        },
      ],
    };
  }

  if (pathname === WHY_OURA_PATHNAME) {
    return {
      footnotes: [
        {
          marker: '1',
          content: t('699_oura_members_survey_footnote'),
        },
        {
          marker: '2',
          content: t('pop_footnote_how_accurate_is_oura'),
          linkProps: {
            href: `https://ouraring.com/blog${localePartialUrl}how-accurate-is-oura/`,
            target: '_blank',
            color: 'inherit',
          },
        },
        {
          marker: '3',
          content: t('pop_footnote_temperature_validated_accurate'),
          linkProps: {
            href: `https://ouraring.com/blog${localePartialUrl}temperature-validated-accurate/`,
            target: '_blank',
            color: 'inherit',
          },
        },
        {
          marker: '4',
          content: t('why_oura_footnote_sleep_staging_algorithm'),
          linkProps: {
            href: `https://ouraring.com/blog${localePartialUrl}new-sleep-staging-algorithm/`,
            target: '_blank',
            color: 'inherit',
          },
        },
      ],
      color: 'sandstone-500',
    };
  }
  if (pathname === MEMBERSHIP) {
    return {
      id: 'legal-footnotes',
      footnotes: [
        {
          marker: '*',
          content: t('footnotes_membership_pricing'),
        },
        {
          marker: '**',
          content: t('footnote_personal_baseline'),
        },
      ],
    };
  }

  if (pathname === BUSINESS_PATHNAME) {
    return {
      footnotes: [
        {
          marker: '1',
          content: t('business_footer_survey_details'),
        },
        {
          marker: '2',
          content: `https://ouraring.com/blog${localePartialUrl}how-accurate-is-oura/`,
        },
        {
          marker: '3',
          content: `https://ouraring.com/blog${localePartialUrl}temperature-validated-accurate/`,
        },
        {
          marker: '4',
          content: `https://ouraring.com/blog${localePartialUrl}new-sleep-staging-algorithm/`,
        },
        {
          marker: '5',
          content: `https://ouraring.com/blog${localePartialUrl}new-sleep-staging-algorithm/`,
        },
      ],
    };
  }

  if (pathname === FITNESS_PATHNAME || pathname === GIFT_PATHNAME) {
    return {
      footnotes: [
        {
          marker: '*',
          content: t('footnote_battery'),
        },
      ],
    };
  }

  if (pathname === STELO_PDP_PATHNAME) {
    return {
      afterContent: t.rich('stelo_pdp_footnotes_bottom_copy', {
        p: function p(chunks: ReactNode): JSX.Element {
          return (
            <TypographyV3 className="text-sm text-inherit">
              {chunks}
            </TypographyV3>
          );
        },
      }),
      className: '!bg-gray-450 !pb-16',
      footnotes: [
        {
          marker: '1',
          content: t('stelo_pdp_footnote_1'),
        },
        {
          marker: '2',
          content: t('stelo_pdp_footnote_2'),
        },
        {
          marker: '3',
          content: t('stelo_pdp_footnote_3'),
        },
        {
          marker: '4',
          content: t('stelo_pdp_footnote_4'),
        },
        {
          marker: '5',
          content: t('stelo_pdp_footnote_5'),
        },
        {
          marker: '6',
          content: t('stelo_pdp_footnote_6'),
        },
      ],
    };
  }

  return { footnotes: [] };
};

export default useFootnotesProps;
