'use client';
//TODO: GROW-2166 refactor and core component move when productizing
import type {
  EcomFooterProps,
  HeaderV3Props,
  LayoutV3Props,
} from '@jouzen/ecom-components';
import { PageLayoutV3 as ControlLayout } from '@jouzen/ecom-components';

import usePageView from '@/analytics/hooks/usePageView';
import useFixedHeader from '@/app/experiments/FixedHeader/useFixedHeader';

import type { HeaderV4Props } from '../Experiments/FixedHeader/HeaderV4';
import { PageLayoutV4 as FixedLayout } from '../Experiments/FixedHeader/PageLayoutV4';
import { usePageLayout } from './hooks/usePageLayout';

export interface PageLayoutV3Props
  extends Omit<
    LayoutV3Props,
    | 'mainLabel'
    | 'footerProps'
    | 'headerProps'
    | 'eyebrowProps'
    | 'footnotesProps'
  > {
  readonly pageName: string;
  readonly footerProps?: Partial<EcomFooterProps>;
  readonly headerProps?: Partial<HeaderV3Props>;
  readonly hideFooter?: boolean;
  readonly hideHeader?: boolean;
}

const PageLayoutV3 = ({
  children,
  className = '',
  pageName,
  containerProps: parentContainerProps,
  footerProps: parentFooterProps,
  headerProps: parentHeaderProps,
  hideFooter = false,
  hideHeader = false,
}: PageLayoutV3Props): JSX.Element => {
  const {
    containerProps,
    eyebrowProps,
    footnotesProps,
    headerProps,
    footerProps,
    observeNode,
  } = usePageLayout({
    parentContainerProps,
    parentFooterProps,
    parentHeaderProps,
  });

  usePageView(pageName);
  const isFixed = useFixedHeader();
  //use container props name to map to a data file that returns for fixedHeader inverse true or false

  return (
    <div
      data-cy={isFixed ? 'page-layout-v4' : 'page-layout-v3'}
      ref={observeNode}
    >
      {isFixed ? (
        <FixedLayout
          className={className}
          containerProps={containerProps}
          eyebrowProps={eyebrowProps!}
          footnotesProps={footnotesProps!}
          footerProps={{ ...footerProps, inverseHeaderOnScrollUp: false }}
          headerProps={{ ...(headerProps as HeaderV4Props), inverse: true }}
          mainLabel="mainLabel"
          hideFooter={hideFooter}
          hideHeader={hideHeader}
        >
          {children}
        </FixedLayout>
      ) : (
        <ControlLayout
          className={className}
          containerProps={containerProps}
          eyebrowProps={eyebrowProps!}
          footnotesProps={footnotesProps!}
          footerProps={footerProps}
          headerProps={headerProps}
          mainLabel="mainLabel"
          hideFooter={hideFooter}
          hideHeader={hideHeader}
        >
          {children}
        </ControlLayout>
      )}
    </div>
  );
};

export default PageLayoutV3;
