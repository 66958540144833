import { cx } from 'class-variance-authority';

import useTailwindThemeValue from '@/app/hooks/useTailwindThemeValue';
import SvgIconAmazonLogoDe from '@/app/icons/IconAmazonLogoDe';
import SvgIconAmazonLogoEn from '@/app/icons/IconAmazonLogoEn';
import SvgIconAmazonLogoEs from '@/app/icons/IconAmazonLogoEs';
import SvgIconAmazonLogoFr from '@/app/icons/IconAmazonLogoFr';
import SvgIconAmazonLogoIt from '@/app/icons/IconAmazonLogoIt';
import SvgIconAmazonLogoJp from '@/app/icons/IconAmazonLogoJp';
import SvgLogoAffirm from '@/app/icons/LogoAffirm';
import SvgLogoBestBuy from '@/app/icons/LogoBestBuy';
import SvgLogoBestBuyWhite from '@/app/icons/LogoBestBuyWhite';
import SvgLogoOura from '@/app/icons/LogoOura';

type VectorImageKey =
  | 'affirm'
  | 'amazon_logo'
  | 'amazon_logo_fr'
  | 'amazon_logo_de'
  | 'amazon_logo_es'
  | 'amazon_logo_it'
  | 'amazon_logo_jp'
  | 'best-buy'
  | 'best-buy-white'
  | 'oura';

interface VectorImageProps {
  /** name of image to load */
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  readonly name: VectorImageKey | string;
  /** optional max width in pixels */
  readonly maxWidth?: number;
  /** optional color string */
  readonly color?: string;
  /** optional width in pixels */
  readonly width?: number;
  /** optional height in pixels */
  readonly height?: number;
  /** optional HTML tagname to use for wrapper (defaults to div) */
  readonly Element?: 'div' | 'span';
  /** optional className */
  readonly className?: string;
}

const DATA_CY = 'vectorimage_icon';

const VectorImage = ({
  name,
  maxWidth,
  width,
  height,
  color = 'sandstone-500',
  Element = 'div',
  className,
}: VectorImageProps): JSX.Element => {
  const colorHex = useTailwindThemeValue('colors', color);

  const getImage = () => {
    const attributes = {
      style: {
        width: width + 'px',
        height: height + 'px',
        maxWidth: maxWidth + 'px',
      },
      'data-cy': DATA_CY,
    };

    switch (name) {
      case 'affirm':
        return <SvgLogoAffirm {...attributes} />;
      case 'amazon_logo':
        return <SvgIconAmazonLogoEn {...attributes} />;
      case 'amazon_logo_fr':
        return <SvgIconAmazonLogoFr {...attributes} />;
      case 'amazon_logo_de':
        return <SvgIconAmazonLogoDe {...attributes} />;
      case 'amazon_logo_es':
        return <SvgIconAmazonLogoEs {...attributes} />;
      case 'amazon_logo_it':
        return <SvgIconAmazonLogoIt {...attributes} />;
      case 'amazon_logo_jp':
        return <SvgIconAmazonLogoJp {...attributes} />;
      case 'best-buy':
        return <SvgLogoBestBuy {...attributes} />;
      case 'best-buy-white':
        return <SvgLogoBestBuyWhite {...attributes} />;
      default:
        return <SvgLogoOura {...attributes} />;
    }
  };

  return (
    <Element
      className={cx('fill-current', className)}
      style={{ color: colorHex! }}
      aria-hidden="true"
      data-cy={DATA_CY}
    >
      {getImage()}
    </Element>
  );
};

export default VectorImage;
