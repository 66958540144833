//TODO: GROW-2166 - After A/B test is done if Fixed header wins we will need to move the changes over to core components, and either create a new V4 version or pipe more props in and refactor, but these components should be deleted in clean up
import {
  ButtonV3,
  GridItem,
  HamburgerBox,
  LayoutGrid,
  LinksCategoryId,
  type LinksData,
  Menu,
  NavLinkV3,
  Oura as OuraLogo,
  ShoppingCartV3,
} from '@jouzen/ecom-components';
import { cva, cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import {
  type MouseEventHandler,
  type ReactElement,
  type ReactNode,
} from 'react';
import type { MessageKey } from 'types/LocaleMessageKey';

import headerData from '../data/headerData';
import { useHeaderV4 } from './_hooks/useHeaderV4';

interface ShopButtonProps {
  href?: string;
  label?: ReactElement | string;
  target?: '_blank';
}

export interface HeaderV4Props {
  readonly accordionOpenAtIndex: number | null;
  readonly count: number;
  readonly currentPath: string;
  readonly menuImageComponent: ReactNode;
  readonly menuLinks: LinksData[] | undefined;
  readonly headerLinks: LinksData[] | undefined;
  readonly headerPosition?: 'fixed' | 'static' | 'fold';
  readonly hideHamburger?: boolean;
  readonly hideLinks?: boolean;
  readonly inverse?: boolean;
  readonly onHamburgerClick?: ({ open }: { open: boolean }) => void;
  readonly onMenuLinkClick: MouseEventHandler<HTMLAnchorElement>;
  readonly onMenuShopButtonClick: MouseEventHandler<HTMLAnchorElement>;
  readonly onShopButtonClick: MouseEventHandler<HTMLButtonElement>;
  readonly shopButton?: ShopButtonProps | false;
  readonly showCart?: boolean;
  readonly onClick: MouseEventHandler<HTMLAnchorElement>;
  readonly onShoppingCartClick: MouseEventHandler<HTMLButtonElement>;
}
const headerComponentVariants = cva(
  'pointer-events-auto z-100 mt-4 w-full py-2 transition-all duration-500',
  {
    variants: {
      visible: {
        true: '',
        false: '',
      },
      headerPosition: {
        fold: 'sticky top-4',
        fixed: 'sticky top-4',
        static: 'relative top-0',
      },
    },
    compoundVariants: [
      {
        //not visble and headerPosition is fold return className
        visible: false,
        headerPosition: 'fold',
        className: 'lg:-top-28',
      },
    ],
  },
);
const headerContainerVariants = cva(
  'absolute inset-x-2 inset-y-0 overflow-hidden rounded-lg before:absolute before:inset-0 before:bg-cream-50 before:bg-opacity-40 before:bg-blend-multiply before:backdrop-blur-xl lg:inset-x-6 lg:transition-opacity',
  {
    variants: {
      visible: {
        true: '',
        false: '',
      },
      headerPosition: {
        fold: '',
        fixed: '',
        static: 'lg:opacity-0',
      },
      positionLessThanTen: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        //visble and position is less than ten
        visible: true,
        positionLessThanTen: true,
        className: 'lg:opacity-0',
      },
      {
        visible: false,
        headerPosition: 'fold',
        className: 'lg:before:opacity-0',
      },
    ],
  },
);
const headerLinksVariants = cva('flex items-center lg:gap-12', {
  variants: {
    inverse: { true: '', false: '' },
    positionLessThanTen: { true: '', false: '' },
  },
  compoundVariants: [
    {
      inverse: false,
      positionLessThanTen: true,
      className: 'text-[#222428]',
    },
    {
      inverse: true,
      positionLessThanTen: true,
      className: 'text-cream-50',
    },
    {
      positionLessThanTen: false,
      className: 'text-[#222428]',
    },
  ],
});

export function HeaderV4({
  accordionOpenAtIndex,
  count,
  currentPath,
  menuImageComponent,
  menuLinks,
  headerLinks,
  headerPosition,
  hideHamburger = false,
  hideLinks = false,
  inverse: _inverse,
  shopButton = {
    href: '/product/rings',
    label: 'shop',
  },
  showCart = true,
  onClick,
  onHamburgerClick,
  onMenuLinkClick,
  onMenuShopButtonClick,
  onShopButtonClick,
  onShoppingCartClick,
}: HeaderV4Props): JSX.Element {
  const {
    blurElement,
    hamburgerElement,
    inverse,
    position,
    open,
    tabIndex,
    visible,
    onHamburgerButtonClick,
    onOutsideClick,
  } = useHeaderV4({ isParentInverse: _inverse, onHamburgerClick });
  const t = useTranslations();
  // use consuming repos menuLinks if provided, otherwise use default data
  const _headerLinks = headerLinks ?? headerData.headerLinks;
  const mainLinks = _headerLinks.filter(
    ({ id }) => id !== LinksCategoryId.ShopCategory,
  );

  return (
    <header
      className={cx(headerComponentVariants({ visible, headerPosition }))}
      data-cy="header"
    >
      <div
        className={cx(
          headerContainerVariants({
            visible,
            headerPosition,
            positionLessThanTen: position < 10,
          }),
        )}
      />
      <LayoutGrid
        className={cx('z-200', {
          'fixed top-6 left-0 right-0 w-full xxl:left-1/2 xxl:-translate-x-1/2':
            open,
          relative: !open,
        })}
      >
        <GridItem colEnd={{ sm: 'main' }} colStart={{ sm: 'main' }}>
          <nav
            className={cx(
              headerLinksVariants({
                inverse,
                positionLessThanTen: position < 10,
              }),
            )}
          >
            <ul className="relative z-200 w-24">
              <NavLinkV3
                aria-label={t('oura_home_link')}
                className="block w-16 py-3 lg:w-20"
                data-cy="nav_logo_link"
                href="/"
                id="nav_home"
                onClick={onClick}
              >
                <OuraLogo />
              </NavLinkV3>
            </ul>
            <div
              className={cx(
                'flex-1 flex items-center justify-end mx-2',
                'transition lg:gap-12',
                'sm:mx-4',
                hideLinks ? 'lg:justify-end' : 'lg:justify-center',
                {
                  'opacity-0 pointer-events-none': open,
                },
              )}
            >
              {!hideLinks ? (
                <ul
                  className={cx('hidden lg:flex lg:gap-12', {
                    'opacity-0': open,
                  })}
                >
                  {mainLinks.map(({ link }) => {
                    if (!link) return null;
                    return (
                      <NavLinkV3
                        className={cx(
                          'py-3 text-stroke-color-transparent text-stroke-width-0.5 transition-all hover:text-stroke-color-current',
                          {
                            'font-bold cursor-default':
                              currentPath === link.href,
                          },
                        )}
                        href={link.href}
                        id={link.id}
                        key={link.id}
                        onClick={onClick}
                        tabIndex={open ? -1 : 0}
                      >
                        {headerLinks ? link.label : t(link.label as MessageKey)}
                      </NavLinkV3>
                    );
                  })}
                </ul>
              ) : null}
              {shopButton && (
                <ButtonV3
                  className={cx({ hidden: open })}
                  data-cy="nav_shopcta"
                  href={shopButton.href}
                  onClick={onShopButtonClick}
                  size="medium"
                >
                  {shopButton.label}
                </ButtonV3>
              )}
            </div>
            {showCart && Boolean(count) && (
              <div
                className={cx({
                  hidden: open,
                })}
              >
                <ShoppingCartV3
                  hiddenHamburger={hideHamburger}
                  inverseHeader={inverse}
                  onShoppingCartClick={onShoppingCartClick}
                  shoppingCartCount={count}
                />
              </div>
            )}
            {!hideHamburger && (
              <HamburgerBox
                hideLinks={hideLinks}
                onClick={onHamburgerButtonClick}
                open={open}
                ref={hamburgerElement}
              />
            )}
          </nav>
        </GridItem>
      </LayoutGrid>
      <Menu
        accordionOpenAtIndex={accordionOpenAtIndex}
        currentPath={currentPath}
        hideLinks={hideLinks}
        menuImageComponent={menuImageComponent}
        menuLinks={menuLinks}
        onMenuLinkClick={onMenuLinkClick}
        onMenuShopButtonClick={onMenuShopButtonClick}
        open={open}
      />
      {/* desktop menu blur */}
      <button
        aria-hidden={!open}
        className={cx(
          'hidden',
          'before:absolute before:top-0 before:right-0 before:bottom-0 before:left-0 before:bg-blend-multiply before:bg-white before:bg-opacity-10 lg:backdrop-blur-[2px]',
          'lg:fixed lg:top-0 lg:left-0 lg:right-0',
          'lg:block lg:transition-opacity lg:-z-10',
          open ? 'lg:bottom-0' : 'lg:bottom-auto lg:opacity-0',
        )}
        onClick={onOutsideClick}
        ref={blurElement}
        tabIndex={tabIndex}
        type="button"
      >
        <span className="sr-only">
          {t('header_hamburger_close_sr_label_2024')}
        </span>
      </button>
    </header>
  );
}

HeaderV4.displayName = 'HeaderV4';
