/* eslint-disable no-process-env */
import { type AbstractIntlMessages } from 'next-intl';

// These are being added to the site. We need to hide them from prod until we are ready
const newLanguages = ['nb', 'sv', 'da', 'nl', 'cs'];

export const locales = [
  'en',
  'de',
  'fi',
  'es',
  'fr',
  'it',
  'ja',
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  ...(process.env.NEXT_PUBLIC_OURA_ENV &&
  process.env.NEXT_PUBLIC_OURA_ENV !== 'production'
    ? newLanguages
    : []),
];

export type Locale = (typeof locales)[number];

export const getMessages = async (locale: string) => {
  return (
    (await import(`../public/locales/${locale}.json`)) as {
      default: AbstractIntlMessages;
    }
  ).default;
};
