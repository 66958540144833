import { useTranslations } from 'next-intl';

import Image from '../Image';

export const MenuImageComponent = (): JSX.Element => {
  const t = useTranslations();

  return (
    <Image
      alt={t('shop_rings')}
      className="h-36 rounded-lg object-cover md:h-auto"
      height={233}
      src={'Mask_BXZVD9.png'}
      width={400}
    />
  );
};
